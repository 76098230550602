import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'antd';

import { Rounds } from './Score';
import './Receiver.css';


const CABO_SCORE_NAMESPACE = 'urn:x-cast:com.cabo-online.score';
// const CABO_GAME_NAMESPACE = 'urn:x-cast:com.cabo-online.play';


export const Receiver = () => {
  // const defaultRounds = [
  //   {
  //     key: 1,
  //     round: 1,
  //     Bob: 1,
  //     Sue: 2,
  //   },
  //   {
  //     key: 2,
  //     round: 2,
  //     Bob: 6,
  //     Sue: -6
  //   },
  //   {
  //     key: 3,
  //     round: 2,
  //     Bob: 6,
  //     Sue: -6
  //   },
  //   {
  //     key: 4,
  //     round: 2,
  //     Bob: 6,
  //     Sue: -6
  //   },
  //   {
  //     key: 5,
  //     round: 2,
  //     Bob: 6,
  //     Sue: -6
  //   },
  //   {
  //     key: 6,
  //     round: 2,
  //     Bob: 6,
  //     Sue: -6
  //   },
  //   {
  //     key: 7,
  //     round: 2,
  //     Bob: 6,
  //     Sue: -6
  //   },
  //   {
  //     key: 8,
  //     round: 2,
  //     Bob: 6,
  //     Sue: -6
  //   },
  // ]

  const [ players, setPlayers ] = useState([]);
  const [ limit, setLimit ] = useState(50);
  const [ rounds, setRounds ] = useState([]);

  useEffect(() => {
    const setUp = (tries) => {
      if (window.cast && window.cast.framework) {
        console.log('Initialise: cast available');

        var framework = window.cast.framework;

        // Configure app, handle system events, custom channels, etc
        var context = framework.CastReceiverContext.getInstance();

        // Playback operations, handle playback-related requests, etc
        // var playerManager = context.getPlayerManager();

        const options = new framework.CastReceiverOptions();
              options.maxInactivity = 600;   
              // options.disableIdleTimeout = true;
              options.skipPlayerLoad = true;    

        // This should be the way to handle multiple connections, but it doesn't seem possible
        //   - https://stackoverflow.com/questions/59850492/multiple-cast-senders-to-one-receiver-caf
        // context.addEventListener(framework.system.EventType.SENDER_CONNECTED, handleNewSender);

        // https://stackoverflow.com/questions/23370019/sending-messages-from-sender-to-custom-receiver-with-chromecast-receiver-api-v2
        // https://developers.google.com/cast/docs/reference/caf_receiver/cast.framework.CastReceiverContext#addCustomMessageListener
        context.addCustomMessageListener(CABO_SCORE_NAMESPACE, handleMessage);

        context.start(options);
      } else if (tries > 0) {
        console.log('Initialise: cast not available; retrying');
        window.setTimeout(setUp, 100, tries-1);
      } else {
        console.log('Initialise: cast not available; giving up');
      }
    }

    setUp(10);
  }, []);

  const handleMessage = (event) => {
    console.log('Custom message: inbound', event);
    // let senderId = event.senderId;
    setPlayers(event.data.players);
    setLimit(event.data.limit);
    setRounds(event.data.rounds);
  }

  return (
    <div className="receiver">
      <Helmet>
        <script src="//www.gstatic.com/cast/sdk/libs/caf_receiver/v3/cast_receiver_framework.js"></script>
      </Helmet>
      <Row>
        <Col className="title" span={10} offset={7}>
          <h1 style={{ textAlign: 'center' }}>Cabo Online</h1>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Rounds players={players} limit={limit} rounds={rounds.slice(-6)} />
        </Col>
      </Row>
    </div>
  );
}
