import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import { Base } from './Site/Base.tsx';
import { Inbound } from './Site/Inbound';
import { Score } from './Site/Score';
import { Rules } from './Site/Rules';
import { Play } from './Site/Play';
import { Receiver } from './Site/Receiver';
// import { Home } from 'Site/Home';

import './App.css';


const App = (props) => {
  return (
    <div className="App">
      <Router>
          <Switch>
            <Route path="/in/cards"><Base><Inbound /></Base></Route>

            <Route path="/score"><Base><Score /></Base></Route>
            <Route path="/rules"><Base><Rules /></Base></Route>
            <Route path="/play"><Base><Play /></Base></Route>

            <Route path="/receiver"><Receiver /></Route>

            <Route path="/"><Redirect to="/score" />{/*<Home />*/}</Route>
          </Switch>
      </Router>
    </div>
  )
}

export default App;
