import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  Row, Col, Typography,
  Form, Input, InputNumber, Button, Popconfirm,
  Collapse, Table, Modal
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { useWindowSize } from '../hooks';
import GoogleCastButton from './GoogleCast'; 

import './Score.css';


const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;


const Players = (props) => {
  const { xs } = useWindowSize();

  const [playerForm] = Form.useForm();
  const [newPlayerForm] = Form.useForm();

  const createPlayer = (values) => {
    props.setPlayers([...props.players, values.newPlayerName]);
    newPlayerForm.resetFields();
  }

  const updatePlayers = (values) => {           // values = {oldName: newName}
    props.setRounds(
      props.rounds.map(round => {               // for each round
        for (const key in values) {             // for each old player name
          if (key !== values[key]) {            // if there is a new name
            round[values[key]] = round[key];    // add the new name and existing score
            delete round[key];                  // delete the old name
          }
        }
        return round;
      })
    );

    props.setPlayers(
      props.players.map(player => values[player])
    );

    playerForm.resetFields()
  }

  const deletePlayer = (playerName, event) => {
    event.preventDefault();
    props.setPlayers(props.players.filter(player => player !== playerName));
  }

  const onNext = () => {
    if (newPlayerForm.isFieldsTouched()) {
      newPlayerForm.submit();
    }

    if (playerForm.isFieldsTouched()) {
      playerForm.submit();
    }

    // Should probably only call ths if onFinish is triggered so user sees any
    // errors
    props.setActivePanel('2');
  }

  return (
    <div>
      <p>
        Enter the name of each player that will be playing the game. You can update this later.
      </p>
      <Form form={playerForm} layout="inline" onFinish={updatePlayers}>
      {
        props.players.map((player, i) => (
          <Row gutter={0} style={{ marginBottom: '10px', width: '100%' }} key={i}>
            <Form.Item hidden />
            <Col xs={1} sm={3}>
              { xs ? '' + (i+1) : 'Player ' + (i+1) }
            </Col>
            <Col xs={15} md={10}>
              <Form.Item name={player} initialValue={player}>
                <Input />
              </Form.Item>
            </Col>
            {/*}
            <Col>
              <Form.Item shouldUpdate>
              {() => (
                <Button
                  htmlType="submit"
                  disabled={!playerForm.isFieldTouched(player)}
                  size="small"
                >
                  <CheckOutlined />
                </Button>)
              }
              </Form.Item>
            </Col>*/}
            <Col>
              <Form.Item>
                <Popconfirm
                  title="Are you sure you want to delete this player?"
                  onConfirm={event => deletePlayer(player, event)}
                >
                  <Button size="small">
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Form.Item>
            </Col>
          </Row>
        ))
      }
      </Form>

      <Form form={newPlayerForm} layout="inline" onFinish={createPlayer}>
        <Row gutter={0} style={{ marginBottom: '10px', width: '100%'}}>
          <Col xs={1} sm={3}>
            { xs ? '' + (props.players.length+1) : 'Player ' + (props.players.length+1) }
          </Col>
          <Col xs={15} md={10}>
            <Form.Item
              name="newPlayerName"
              rules={[
                {
                  required: true,
                  message: 'New players must have a name'
                },
                {
                  validator: (_, value) =>
                    props.players.includes(value) ? Promise.reject('Each player must have a unique name') : Promise.resolve()
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button htmlType="submit" size="small"><Text type="success">Add</Text></Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Button
        type="primary"
        style={{ marginLeft: '10px' }}
        onClick={onNext}
      >Save and continue</Button>

    </div>
  );
}


const Limit = (props) => {
  const [form] = Form.useForm();

  const saveLimit = (values) => {
    props.setLimit(values.limit);
  }

  const nextPanel = () => {
    form.submit();
    props.setActivePanel('3');
  }

  return (
    <div>
      <p>Players will be removed from the game when they hit this score.</p>
      <Form form={form} onFinish={saveLimit}>
        <Form.Item name="limit" label="Limit" initialValue={props.limit}>
          <InputNumber type="number" min={0} pattern="[0-9]*" />
        </Form.Item>
        <Form.Item hidden><Button htmlType="submit">Submit</Button></Form.Item>
      </Form>
      <Button
        type="primary"
        style={{ marginLeft: '10px' }}
        onClick={nextPanel}
      >Save and continue</Button>
    </div>
  );
}


export const Rounds = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const totals = props.players.map(player => {
    return props.rounds.reduce((accumulator, round) => {
      const score = round[player]
      return accumulator + (Number.isInteger(score) ? score : 0)
    }, 0);
  });

  const columns = [
    {
      title: '#', dataIndex: 'round', key: 'round', align: 'center', width: 50
    },
    ...props.players.map((player, i) => ({
        title: <Text disabled={totals[i] > props.limit ? true : false}>{player}</Text>,
        dataIndex: player,
        key: player,
        align: 'center',
        render: (text, record) =>
          <Text disabled={totals[i] > props.limit ? true : false}>{record[player]}</Text>
      })
    ),
    // {
    //   title: '', dataIndex: 'edit', key: 'edit', align: 'center', width: 40,
    //   render: (text, record) =>
    //     <EditOutlined onClick={() => showEditRound(record)} />
    // },
  ];

  const showNewRound = () => {
    form.resetFields();

    // I *think* this is necessary because the initial value on the Form.Item
    // isn't set until this method is called
    const nextRound = props.rounds.length + 1;
    form.setFieldsValue({ key: nextRound, round: nextRound});

    setIsModalVisible(true);
  }

  const showEditRound = (values) => {
    form.setFieldsValue({...values, editing: true});
    setIsModalVisible(true);
  }

  const saveRound = (values) => { // = {key: 1, round: 1, playerName: score}
    props.setRounds([
      ...props.rounds.slice(0, values.round - 1),
      values,
      ...props.rounds.slice(values.round)
    ]);

    form.resetFields();
    setIsModalVisible(false);
  }

  const deleteRound = (values) => {
    let rounds = [
      ...props.rounds.slice(0, values.round - 1),
      ...props.rounds.slice(values.round)
    ]

    props.setRounds(rounds.map((round, i) => {
      round.round = i+1;
      round.key = i+1;
      return round;
    }));

    form.resetFields();
    setIsModalVisible(false);
  }

  const currentRound = Math.min(form.getFieldValue('round') || props.rounds.length + 1, props.rounds.length + 1);
  const editing = form.getFieldValue('editing') === true;

  return (
    <div>
      <Table
        columns={columns}
        dataSource={props.rounds}
        pagination={false}
        bordered
        onRow={(record, i) => {
          return {
            onClick: event => showEditRound(record),
          }
        }}
        summary={pageData => {
          return ( (props.rounds.length > 0) ?
            <Table.Summary.Row>
              <Table.Summary.Cell>Total</Table.Summary.Cell>
              {
                totals.map((total, i) => (
                  <Table.Summary.Cell key={i}>
                    <div style={{textAlign: 'center'}}>
                      <Text disabled={total > props.limit ? true : false}>{total}</Text>
                    </div>
                  </Table.Summary.Cell>
                ))
              }
            </Table.Summary.Row> : null
          );
        }}
      />

      <Modal
        title={(editing ? 'Edit' : 'Add') + ' scores for round ' + currentRound}
        visible={isModalVisible}
        onOk={event => form.submit()}
        onCancel={event => setIsModalVisible(false)}
      >
        <Form form={form} requiredMark={false} onFinish={saveRound}>
          <Form.Item hidden name="key" initialValue={props.rounds.length + 1}><InputNumber /></Form.Item>
          <Form.Item hidden name="round" initialValue={props.rounds.length + 1}><InputNumber /></Form.Item>
          {
            props.players.map((player, i) => (
              <Form.Item
                key={i}
                labelCol={{span: 4}} wrapperCol={{span: 8}}
                label={totals[i] > props.limit ? <Text disabled>{player}</Text> : player}
                name={player}
                initialValue={0}
                required={true}
                rules={[
                  {
                    required: true,
                    message: 'Every player needs a score',
                  }
                ]}
              >
                <InputNumber
                  type="number"
                  min={-6}
                  pattern="[0-9]*" />
              </Form.Item>
            ))
          }
          {editing ?
            <Form.Item wrapperCol={{ xs: {offset: 0}, sm: {offset: 4} }}>
            <Popconfirm
              title="Are you sure you want to delete the scores for this round?"
              onConfirm={event => deleteRound(form.getFieldsValue())}
            >
              <Button danger>Delete round</Button>
            </Popconfirm>
            </Form.Item> : null
          }
          <Form.Item hidden><Button htmlType="submit">Submit</Button></Form.Item>
        </Form>
      </Modal>

      <Button
        style={{ marginTop: '30px' }} block
        onClick={showNewRound}
        type="primary"
      >
        Add scores
      </Button>

      <Paragraph className="info" style={{ marginTop: '10px' }}>
        Players that have a total score greater than the limit ({props.limit})
        have been eliminated and are greyed out. Tap or click on a row to edit
        it.
      </Paragraph>
    </div>
  );
}


export const Score = () => {
  const storage = window.localStorage;

  const fromStorage = (key, defaultValue) => {
    const data = storage.getItem(key);
    return data ? JSON.parse(data) : defaultValue;
  }

  const toStorage = (key, value) => {
    storage.setItem(key, JSON.stringify(value));
  }

  const reset = () => {
    storage.clear();
    setPlayers([]);
    setLimit(0);
    setRounds([]);
    setActivePanel('1');
  }


  // Player controls
  const [players, setPlayers]         = useState(fromStorage('players', []));
  const [limit, setLimit]             = useState(fromStorage('limit',  50));
  const [rounds, setRounds]           = useState(fromStorage('rounds', []));
  const [activePanel, setActivePanel] = useState('1');

  useEffect(() => {
    toStorage('players', players);
    toStorage('limit', limit);
    toStorage('rounds', rounds);
  }, [players, limit, rounds]);

  const props = {
    players: players, setPlayers: setPlayers,
    limit: limit, setLimit: setLimit,
    rounds: rounds, setRounds: setRounds,
    activePanel: activePanel, setActivePanel: setActivePanel,
  }

  return (
		<div style={{paddingTop: '30px'}}>
      <Row>
        <Col
          xs={{ offset: 1, span: 22 }}
          md={{ offset: 4, span: 16 }}
        >
          <Title>
            Score
            <GoogleCastButton players={players} limit={limit} rounds={rounds} />
          </Title>
          <Paragraph>
            Keep track of the score in your real life Cabo game.
          </Paragraph>
          <div>
            {players.length > 0 ? (
              <Popconfirm
                title="Remove current game and start a new one?"
                onConfirm={reset}
              >
                <Button><Text type="success">New game</Text></Button>
              </Popconfirm>
            ) : null }
            <Link to="/rules">
              <Button style={{ marginBottom: '50px', marginLeft: '10px' }}>
                Learn the rules
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ offset: 1, span: 22 }}
          md={{ offset: 4, span: 16 }}
        >
          <Collapse accordion activeKey={activePanel} onChange={setActivePanel}>
            <Panel header="Players" key="1">
              <Players {...props} />
            </Panel>
            <Panel header="Limit" key="2">
              <Limit {...props} />
            </Panel>
            <Panel header="Score" key="3" className="scorePanel">
              <Rounds {...props} />
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </div>
	);
}
