import { Row, Col, Typography, Card } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;


export const Inbound = () => {
  return (
    <div style={{ paddingTop: '30px' }}>
      <Row>
        <Col
          xs={{ offset: 1, span: 22 }}
          md={{ offset: 4, span: 16 }}
        >
          <Title>Welcome to Cabo!</Title>
          <Paragraph>
            Congratulations on your deck of high quality Cabo cards.
          </Paragraph>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ textAlign: 'center' }}>
        <Col
          xs={{ offset: 4, span: 16 }}
          md={{ offset: 4, span: 5 }}
        >
          <Link to="/rules">
            <Card hoverable>
              <img src="/logo192.png" alt="cards" style={{ width: '100%', marginBottom: '10px' }} />
              <Title level={3}>Learn to play</Title>
              <Paragraph>All you need is a deck.</Paragraph>
            </Card>
          </Link>
        </Col>
        <Col xs={{ offset: 4, span: 16 }} md={{ offset: 0, span: 5 }}>
          <Link to="/score">
            <Card hoverable>
                <img src="/phone.png" alt="phone" style={{ width: '100%', marginBottom: '10px' }} />
                <Title level={3}>Live scoring</Title>
                <Paragraph>All you need is your phone.</Paragraph>
            </Card>
          </Link>
        </Col>
        <Col xs={{ offset: 4, span: 16 }} md={{ offset: 0, span: 5 }}>
          <Card disabled>
              <img src="/pacman.png" alt="pacman" style={{ width: '100%', marginBottom: '10px' }} />
              <Title level={3}>Play online</Title>
              <Paragraph>Coming soon.</Paragraph>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
