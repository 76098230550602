import { useEffect, useState } from 'react';


export const useWindowSize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleWindwoResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleWindwoResize);

    return () => window.removeEventListener('resiize', handleWindwoResize);
  }, []);

  const xs = width <= 576;
  const sm = width <= 768;
  const md = width <= 992;
  const lg = true; //width <= 1200;

  return { width, height, xs, sm, md, lg}
}
