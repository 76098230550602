import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';


const CABO_SCORE_NAMESPACE = 'urn:x-cast:com.cabo-online.score';
// const CABO_GAME_NAMESPACE = 'urn:x-cast:com.cabo-online.play';


/* Dashboard:
 *   - https://cast.google.com/publish/#/overview
 () 
 * References:
 *   - https://cast.google.com/publish/
 *   - https://codelabs.developers.google.com/codelabs/cast-receiver
 *   - https://github.com/googlecast/CastCameraReceiver
 *   - https://github.com/boombatower/chromecast-dashboard/
 *   - https://github.com/TecladistaProd/react-chromecast/blob/master/src/components/CastProvider.tsx
 *   - https://github.com/KevinGaudin/Cast-TicTacToe-chrome
 * 
 * Lifecycle:
 *   1. Framework (window.cast.framework) is loaded when page loads
 *   2. Session (framework.CastContext.getInstance().getCurrentSession()) is loaded
 *      when user connects to cast device: 'CONNECTED' is triggered
 */


const GoogleCastButton = (props) => {
  const [ castFramework, setCastFramework ] = useState();

  useEffect(() => {
    window.__onGCastApiAvailable = (isAvailable) => {
      if (isAvailable) {
        console.log('Initialise: cast available');

        let framework = window.cast.framework

        framework.CastContext.getInstance().setOptions({
          receiverApplicationId: '7D4FDA29',
          // receiverApplicationId: window.chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
        });

        setCastFramework(framework);

        framework.CastContext.getInstance().addEventListener(
          framework.CastContextEventType.CAST_STATE_CHANGED,
          handleCastStateChanged
        );    
      }
    }
  }, []);

  const handleCastStateChanged = (event) => {
    console.log('state change', event);
    if (event.castState === 'CONNECTED') {
      // const session = window.cast.framework.CastContext.getInstance().getCurrentSession();
      // session.addMessageListener(CABO_SCORE_NAMESPACE, handleMessage);
      castMessage(props);
    }
  }

  const castMessage = (message) => {
    let framework = castFramework || window.cast.framework;
    const session = framework.CastContext.getInstance().getCurrentSession();

    if (session) {
      console.log('Cast Message: sending message', message)

      session.sendMessage(CABO_SCORE_NAMESPACE, message, () => {
        console.log('Cast Message: successfully sent');
      }, (error) => {
        console.log('Cast Message: failed with error', error);
      });

      // This is how you would render a video
      // const mediaInfo = new window.chrome.cast.media.MediaInfo(
      //   'https://drive.google.com/uc?id=1f7Bsxdeiw6JaVKr9IVmHmv5a2kStJFtK',
      //   'mp4'
      // );
      // const mediaInfo = new window.chrome.cast.media.MediaInfo(
      //   'Test',
      //   'Custom'
      // );
      // mediaInfo.customData = {data: props};
      //
      // const request = new window.chrome.cast.media.LoadRequest(mediaInfo);
      //
      // session.loadMedia(request).then(
      //   () => {
      //     console.log('Loaded!');
      //     const player = new castFramework.RemotePlayer();
      //   },
      //   (error) => console.log('didnt load', error)
      // );
    }
  }

  useEffect(() => {
    if (castFramework) {
      castMessage(props);
    }
  }, [props])

  return (
      <span style={{ float: 'right' }}>
        <Helmet>
          <script src="//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1"></script>
        </Helmet>
        <div style={{ width: '30px', display: 'inline-block' }}>
          <google-cast-launcher></google-cast-launcher>
        </div>
      </span>
  );
}

export default GoogleCastButton;
