import { Row, Col, Anchor, Typography,
         Button, List, Card, Collapse, BackTop } from 'antd';
import { Link as RouterLink } from 'react-router-dom';

import { useWindowSize } from '../hooks'
import './Rules.css';

const { Link } = Anchor;
const { Panel } = Collapse;
const { Title, Paragraph, Text } = Typography;


const Red = (props) => <Text style={{ color: 'red' }}>{ props.children }</Text>


const specialCards = [
  {
    title: 'Peek',
    cards: '7 or 8',
    action: 'You may peek briefly at one of the cards in your hand.',
  },
  {
    title: 'Spy',
    cards: '9 or 10',
    action: "You may spy briefly on one of the cards in one of your opponent's hands.",
  },
  {
    title: 'Blind swap',
    cards: 'J or Q',
    action: "Swap one of your cards for an opponent's card without looking.",
  },
  {
    title: 'Spy and swap',
    cards: <span>King<p>🂮<Red>🃎</Red>🃞</p></span>,
    action: "Spy on an opponent's card. If you like it, swap it for one of your own. Note that the King of Hearts is not a special card.",
  },
];

const cardValues = [
  {
    title: 'King of Hearts',
    avatar: '-1',
    description: 'The King of Hearts is the most valuable card you can hold. It is the only card that can reduce your score below zero.',
  },
  {
    title: 'Joker',
    avatar: '0',
    description: 'The Joker adds nothing to your score.',
  },
  {
    title: 'Ace',
    avatar: '1',
    description: 'The Ace adds 1 to your score.',
  },
  {
    title: 'Number cards',
    avatar: '2-10',
    description: 'Number cards are worth their face value.',
  },
  {
    title: 'Face cards',
    avatar: '11-13',
    description: 'The Jack, Queen, and King are worth 11, 12, and 13, respectively.',
  },
]


export const Rules = () => {
  const { width, xs, sm, md, lg } = useWindowSize();

	return (
		<div style={{ paddingTop: '30px' }}>
      <Row>
        <Col
          xs={{ offset: 1, span: 22 }}
          md={{ offset: 4, span: 16 }}
        >
          <Title>Learn to play Cabo</Title>
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ offset: 1, span: 22 }}
          md={{ offset: 0, span: 4 }}
        >
          <Anchor targetOffset={80} affix={!xs && !sm} offsetTop={64} style={{ paddingBottom: '5px', borderBottom: 'solid 1px #eee'}}>
            <Link href="#aim" title="Aim" />
            <Link href="#setup" title="Setting up">
              <Link href="#setup-deal" title="Dealing" />
              <Link href="#setup-start" title="Starting a round" />
            </Link>
            <Link href="#play" title="Playing">
              <Link href="#play-playing" title="Playing a card" />
              <Link href="#play-swap" title="Swapping a card" />
              <Link href="#play-discard" title="Discarding a card" />
              <Link href="#play-snap" title="Snapping a card" />
              <Link href="#play-penalty" title="Penalties" />
            </Link>
            <Link href="#end" title="Ending a round">
              <Link href="#end-challenge" title="Challenging for victory" />
              <Link href="#end-score" title="Scoring a round" />
            </Link>
          </Anchor>
          <BackTop />
        </Col>
        <Col
          xs={{ offset: 1, span: 22 }}
          md={{ offset: 0, span: 16 }}
        >
          <Title level={2} id="aim">Aim</Title>
          <Paragraph>
            The aim of Cabo is to end each round with the lowest total score.
            You do this by getting rid of your cards or trading them for lower value cards.
            Most of the cards in Cabo remain face-down for most of the game so a great memory and quick reflexes are key.
            Use our online scoring tool to keep track of scores in a game:
          </Paragraph>
          <Paragraph>
            <RouterLink to="/score">
              <Button>
                <Text type="success">Keep track of scores</Text>
              </Button>
            </RouterLink>
          </Paragraph>

          <Title level={2} id="setup">Setting up</Title>
          <Paragraph>
            Cabo works best with <strong>4</strong> -- <strong>8</strong> players.
          </Paragraph>

            <Title level={3} id="setup-deal">Dealing</Title>
            <Paragraph>
              Players take turns as the dealer. You decide who deals first.
            </Paragraph>
            <Paragraph>
              Deal <strong>4</strong> cards <strong>face down</strong> to each player.
            </Paragraph>
            <Paragraph>
              Cards should be arranged in a 2&times;2 grid in front of each player.
            </Paragraph>
            <Paragraph>
              The cards remaining in the deck should be placed in face down in the center. Leave room for a burn pile beside the deck.
            </Paragraph>

            <Title level={3} id="setup-start">Starting a round</Title>
            <Paragraph>
              Each player has a few seconds to peak at the <strong>two nearest cards</strong> from their hand and memorise them.
            </Paragraph>
            <Paragraph>
              Once the players have returned their cards face down to their hand the dealer should flip the top card from the deck over into the burn pile.
            </Paragraph>

          <Title level={2} id="play">Playing</Title>
          <Paragraph>
            During each player's turn they pick up a card from the deck, <strong>play</strong> it, <strong>swap</strong> it, or <strong>discard</strong> it,
            after which their turn ends.
          </Paragraph>
          <Paragraph>
            At any time during a turn, any player can also <strong>snap</strong> a card.
          </Paragraph>

            <Title level={3} id="play-playing">Playing a card</Title>
            <Paragraph>
              Some cards allow the player to take special actions that either give them information or allow them to acquire cards from other players.
              To use a special card, the player places it on the discard pile and states their intent to play it.
            </Paragraph>
            <List
              header={<Title level={4}>Special cards</Title>}
              bordered
              dataSource={specialCards}
              style={{ marginBottom: '30px' }}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<div style={{ width: '100px', fontSize: '1.5em', textAlign: 'center' }}>{item.cards}</div>}
                    title={item.title}
                    description={item.action}
                  />
                </List.Item>
              )}
            />

            <Title level={3} id="play-swap">Swapping a card</Title>
            <Paragraph>
              The goal of Cabo is to keep your score as low as possible.
              Therefore, you need to make sure your hand contains cards that have as low of a value as possible.
            </Paragraph>
            <List
              header={<Title level={4}>Card values</Title>}
              bordered
              dataSource={cardValues}
              style={{ marginBottom: '30px' }}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<div style={{ width: '100px', fontSize: '1.5em', textAlign: 'center' }}>{item.avatar}</div>}
                    title={item.title}
                    description={item.description}
                  />
                </List.Item>
              )}
            />

            <Title level={3} id="play-discard">Discarding a card</Title>
            <Paragraph>
              If a card is not a special card and the player does not want to swap it for one of the cards in their hand then they discard it by placing it face up on the discard pile.
            </Paragraph>

            <Title level={3} id="play-snap">Snapping a card</Title>
            <Paragraph>
              At any time during a player's turn, any player in the game can take any card from any players hand and place it face up on the burn pile.
            </Paragraph>
            <Paragraph>
              If the "snapped" card matches the card below it in the burn pile then the player that snapped the card loses one of theirs.
            </Paragraph>
            <Paragraph>
              If the snapped card came from the player's deck, then a gap should remain
            </Paragraph>

            <Title level={3} id="play-penalty">Penalties</Title>
            <Paragraph>
              If a player performs an illegal action then they are required to add another card from the deck to their hand. They may not look at this new card and it must be placed in a new slot on the table, not in one of the absent slots of the original four cards.
            </Paragraph>

          <Title level={2} id="end">Ending a round</Title>
          <Paragraph>
            A player is able to end a round when it is their turn and if they believe the cards in front of them sum to a score of <strong>5 or less</strong>.
            In that case they may announce "Cabo" to the table <strong>instead of</strong> beginning their turn by picking up a card.
          </Paragraph>
          <Paragraph>
            Once a player has called "Cabo" their cards are off limit and they can no longer participate in the round.
            The round continues, which all other players getting one more turn.
            When play returns to the player that called "Cabo" the round is over.
          </Paragraph>

            <Title level={3} id="end-challenge">Challenging for victory</Title>
            <Paragraph>
            </Paragraph>

            <Title level={3} id="end-score">Scoring a round</Title>
            <Paragraph>
            </Paragraph>

            {/*Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.*/}

        </Col>
      </Row>
    </div>
	);
}
