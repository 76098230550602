import { useState } from 'react';
import { useLocation, Link, NavLink } from 'react-router-dom';
import { Layout, Menu, Row, Col, Image, Drawer } from 'antd';

import { MenuOutlined } from '@ant-design/icons';

import { useWindowSize } from '../hooks';
import './Base.css';

const { Header, Content, Footer } = Layout;


export const Base = (props) => {
  return (
    <Layout>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <Row wrap={false}>
          <Col flex="">
            <div className="logo">
              <Link to="/">
                <Image
                  src="/logo192.png"
                  alt="Logo"
                  width={30}
                />
                <span style={{ marginLeft: '5px' }}>Cabo Online</span>
              </Link>
            </div>
          </Col>
          <Col flex="auto" style={{ textAlign: 'right' }}>
            <AppMenu />
          </Col>
        </Row>
      </Header>
      <Content className="site-layout" style={{ marginTop: 64, minHeight: 'calc(100vh - 64px)' }}>
        {props.children}
      </Content>
      <Footer>
      </Footer>
    </Layout>
  );
}


const AppMenu = () => {
  const [ visible, setVisible ] = useState(false);
  const routerLocation = useLocation();
  const { xs } = useWindowSize();

  const menu = (
    <Menu
      className="menu"
      mode={ xs ? 'inline' : 'horizontal' }
      selectedKeys={[routerLocation.pathname]}
      onClick={() => setVisible(false)}
    >
      <Menu.Item key="/score"><NavLink to="/score">Score</NavLink></Menu.Item>
      <Menu.Item key="/rules"><NavLink to="/rules">Rules</NavLink></Menu.Item>
      <Menu.Item key="/play"><NavLink to="/play">Play</NavLink></Menu.Item>
    </Menu>
  );

  return (
    (xs ? (
      <>
        <Drawer
          title="Menu"
          className="drawer-menu"
          placement="right"
          closable={true}
          onClose={() => setVisible(false)}
          visible={visible}
        >{menu}</Drawer>
        <MenuOutlined
          onClick={() => setVisible(true)}
          style={{ marginRight: '5px' }}
        />
      </>
      ) : (
        <div style={{ float: 'right' }}>
          {menu}
        </div>
      )
    )
  );
}
