import { Row, Col, Typography, Skeleton, Alert } from 'antd';

// import { WEBSOCKET_URL } from './env';
// import { init } from '../Game/Game';


const { Title } = Typography;


const columnStyle = {
  xs: { offset: 1, span: 22 },
  md: { offset: 4, span: 16 },
}


export const Play = (props) => {
  return (
    <div style={{ paddingTop: '30px' }}>
      <Row>
        <Col {...columnStyle}>
          <Title>Play Cabo online</Title>
        </Col>
      </Row>
      <Row>
        <Col {...columnStyle}>
          <Alert
            message="Coming soon"
            description="Cabo Online is under development and will be available soon."
            type="info"
            showIcon
          />
        </Col>
      </Row>
      <Row>
        <Col {...columnStyle}>
          <Skeleton />
        </Col>
      </Row>
    </div>
  );
}


// class GameApp extends React.Component {
//   canvas: React.HTMLDivElement;

//   constructor(props) {
//     super(props);

//     this.state = {
//       gameId: '',
//       gameExists: false,
//       gameVisible: false,
//       gameState: {},
//       playerState: {},
//       playerName: 'player1',
//     }

//     this.connect = this.connect.bind(this);
//     this.parseMessage = this.parseMessage.bind(this);
//     this.startGame = this.startGame.bind(this);
//     this.joinGame = this.joinGame.bind(this);
//     this.createGame = this.createGame.bind(this);
//     this.updateName = this.updateName.bind(this);
//   }

//   componentDidMount() {
//     this.connect();
//   }

//   componentWillUnmount() {
//     if (this.Game !== undefined) {
//       console.log('End', this.Game);
//       this.Game.app.stop();
//       this.Game.app.destroy();
//       this.canvas.removeChild(this.Game.app.view);
//     }

//     if (this.socket !== undefined) {
//       this.socket.close();
//     }
//   }

//   connect() {
//     return;
//     this.socket = new WebSocket(WEBSOCKET_URL);
//     console.log('WS Connecting');

//     this.socket.onerror = (error) => {
//       console.log('WS Error', error);
//       this.socket.close();
//     }

//     this.socket.onclose = (event) => {
//       console.log('WS Closing', event);
//       this.setState({gameExists: false});
//       setTimeout(() => {this.connect();}, 1000);
//     }

//     this.socket.onmessage = this.parseMessage;
//   }

//   startGame() {
//     this.Game = init(
//       this.state.gameId,
//       this.state.gameState,
//       this.state.playerState,
//       this.socket,
//     );
//     this.canvas.appendChild(this.Game.app.view);

//     // https://github.com/sindresorhus/screenfull.js
//     // if (document.fullscreenElement) {
//     //   document.exitFullscreen();
//     //   this.canvas.style = {display: 'none'};
//     //   return;
//     // }

//     this.setState({gameVisible: true});
//     window.scrollTo(0, 1);
//     if (document.fullscreenEnabled) {
//       // this.canvas.requestFullscreen();
//     }
//   }

//   parseMessage(msg) {
//     let data = JSON.parse(msg.data);

//     if (data.type === 'error') {
//       console.log('Server error', data);
//       return;
//     } else {
//       console.log('Server message', data);
//     }

//     if (data.type === 'gameState') {
//       this.setState({
//         gameExists: true,
//         gameId: data.gameId,
//         gameState: data,
//       });

//       if (this.Game) {
//         this.Game.updateGameState(data);
//       }
//       return;
//     }

//     if (data.type === 'playerState') {
//       this.setState({
//         gameExists: true,
//         gameId: data.gameId,
//         cliendId: data.clientId,
//         playerState: data,
//       })

//       if (this.Game) {
//         this.Game.updatePlayerState(data);
//       }
//       return;
//     }
//   }

//   createGame() {
//     this.socket.send(JSON.stringify({
//       type: 'createGame',
//       playerName: this.state.playerName,
//     }));
//   }

//   joinGame() {
//     this.socket.send(JSON.stringify({
//       type: 'joinGame',
//       gameId: this.state.gameId,
//       clientId: this.state.clientId,
//       playerName: this.state.playerName,
//     }));
//   }

//   updateName() {
//     this.socket.send(JSON.stringify({
//       type: 'updateName',
//       gameId: this.state.gameId,
//       clientId: this.state.clientId,
//       playerName: this.state.playerName,
//     }));
//   }

//   render() {
//     let gameSetup = (
//       <div className="middle">
//         <p>Game:</p>
//         <input type="text"
//                value={this.state.gameId}
//                onChange={(event) => this.setState({gameId: event.target.value})}
//                placeholder="Game id" />
//         <button onClick={this.joinGame}>Join</button>
//         <button className="wide" onClick={this.createGame}>Create</button>
//       </div>
//     );

//     let players = [];
//     if (this.state.gameState.players !== undefined) {
//       players = Object.values(this.state.gameState.players).map((player) => {
//         return (
//           <li key={player.position}>
//             {player.name} {(player.connected === true) ? '(connected)' : ''}
//           </li>
//         )
//       });
//     }

//     let playerSetup = (
//       <div className="middle" id="players">
//         <p>Game: {this.state.gameId}</p>
//         <p>Players: {this.state.playerState.id}</p>
//         <input type="text"
//                value={this.state.playerName}
//                onChange={(event) => this.setState({playerName: event.target.value})}
//                placeholder="Your name" />
//         <button onClick={this.updateName}>Update</button>
//         <ol>
//           {players}
//         </ol>
//         <button className="wide" onClick={this.startGame}>Start</button>
//       </div>
//     );

//     return (
//       <div id="config"
//            style={{display: this.state.gameVisible ? 'none': 'block'}}>
//         <h1>Welcome to the Cabo Cup</h1>
//         {(this.state.gameExists) ? playerSetup : gameSetup}
//       </div>
//       <div id="game"
//           style={{display: this.state.gameVisible ? 'block' : 'none'}}
//           ref={(thisElem) => {this.canvas = thisElem}} />
//     );
//   }
// }
